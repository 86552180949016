
<template>
  <!-- страница проекты -->
    <PageHeader :title="title" />

    <!-- инфо-блоки -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <div class="row g-4 mb-3">
      <div class="col-sm-auto" v-if="this.checks['001'] == true">
        <div>
          <button v-if="this.checks['001'] == true" @click="infoblockShow = !infoblockShow" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
        </div>
      </div>
      <div class="col-sm-auto">
        <div>
          <a @click="add()" class="btn btn-success"
            ><i class="ri-add-line align-bottom me-1"></i> {{ $t('Add') }}</a
          >
        </div>
      </div>
      <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-2">
          <Filter :user='this.user' v-if="objParams && (dataParams && dataParams.status == true)" :dataParams="dataParams" :objParams="objParams" @changeFilter="changeFilter" @clearFilter="clearFilter"  />
          <div class="search-box ms-2">
            <input type="text" class="form-control" v-model="search" @input="inputSearch(search)" :placeholder="$t('searc_inp')" />
            <i class="ri-search-line search-icon"></i>
          </div>
          
        </div>
      </div>
    </div>
  
    <div class="row g-0 text-center text-sm-start align-items-center mb-4" >
          <div class="col-sm-6" >
              <div >
                  <p class="mb-sm-0 text-muted">{{ $t('Total') }} <span class="fw-semibold">{{ objPages.show }}</span> {{ $t('outOf') }} <span class="fw-semibold text-decoration-underline">{{ objPages.total }}</span></p>
              </div>
          </div>
          <!-- end col -->
          <div class="col-sm-6" >
              <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                  <div class="pagination-wrap hstack gap-2" style="margin-right:5px;" >
                    <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                      <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
                    </select>
                  </div>
                  <li v-for="(pageNumber, index) in objPages.links" :key="index" :class="`page-item copy ${pageNumber.current == 'true' ? 'active' : null}`">
                      <a class="page-link" @click="getdata(pageNumber.page)">{{ pageNumber.page }}</a>
                  </li>
              </ul>
          </div><!-- end col -->
      </div>

    <div class="row">
      <div
        class="col-xxl-3 col-sm-6 project-card"
        v-for="(item, index) of rows"
        :key="index"
      >
        <div class="card" >
          <div class="card-body">
            <!-- <div class="p-3 mt-n3 mx-n3 bg-soft-danger rounded-top"> -->
            <div :class="`p-3 mt-n3 mx-n3 ${this.mutatepriority(item.priority).colorProj} rounded-top`">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h5 class="mb-0 fs-14 click" @click="open(item)"><b># {{ item.projectId }} | {{ item.projectName }}</b></h5>
                </div>
                <div class="flex-shrink-0">
                  <div class="d-flex gap-1 align-items-center my-n2">
                    
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <MoreHorizontalIcon class="icon-sm"></MoreHorizontalIcon>
                      </button>

                      <div class="dropdown-menu dropdown-menu-end">
                        <a
                          class="dropdown-item copy"
                          @click="open(item)"
                          ><i
                            class="ri-eye-fill align-bottom me-2 text-muted"
                          ></i>
                          {{ $t('view') }}</a
                        >
                        <a
                          v-if="perms[351] || perms[2000]"
                          class="dropdown-item copy"
                          @click="toCopy(item)"
                          ><i
                            class="ri-file-copy-2-fill align-bottom me-2 text-muted"
                          ></i>
                          {{ $t('Duplicate') }}</a
                        >
                        <!-- <a
                          class="dropdown-item"
                          @click="edit(item)"
                          ><i
                            class="ri-pencil-fill align-bottom me-2 text-muted"
                          ></i>
                          {{ $t('Edit') }}</a
                        > -->
                        <div class="dropdown-divider"></div>
                        <a
                          v-if="perms[353] || perms[2000]"
                          class="dropdown-item copy"
                          @click="remove(item)"
                          ><i
                            class="ri-delete-bin-fill align-bottom me-2 text-muted"
                          ></i>
                          {{ $t('Delete') }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="py-3">
              <div class="row gy-3">
                <div class="col-6" v-if="item.status">
                  <div >
                    <p class="text-muted mb-1">{{ $t('Status') }}</p>
                    <div :class="`badge ${this.mutatestatus(item.status).color} fs-12`">
                      {{ this.mutatestatus(item.status).name }}
                    </div>
                  </div>
                </div>
                <div class="col-3" v-if="item.priority">
                  <div>
                    <p class="text-muted mb-1">{{ $t('priority') }}</p>
                    <div :class="`badge ${this.mutatepriority(item.priority).color} fs-12`">
                      {{ this.mutatepriority(item.priority).name }}
                    </div>
                  </div>
                </div>
                <div class="col-3 text-end" v-if="item.deadlineId">
                  <div>
                    <p class="text-muted mb-1">Deadline</p>
                    <h5 class="fs-14">{{ this.getDeadline(item.deadlineId) }}</h5>
                  </div>
                </div>
              </div>

              <div class="align-items-center mt-3">
                <p class="text-muted mb-0 me-2">{{$t('team')}} :</p>
                <div>
                  <span class="fs-10 badge bg-info" style="margin-right: 5px;" v-for="opt in item.workers" :key="opt">{{ opt.workerName }}</span>
                </div>
              </div>
            </div>

            <div v-if="item.progressPercentByEdits != 0">
              <div class="d-flex mb-2">
                <div class="flex-grow-1">
                  <div>{{ $t('ProgressEditBar') }}</div>
                </div>
                <div class="flex-shrink-0">
                  <div>{{item.progressPercentByEdits}}%</div>
                </div>
              </div>
              <div class="progress progress-sm animated-progess">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="`width: `+ item.progressPercentByEdits +`%`"
                ></div>
                <!-- /.progress-bar -->
              </div>
              <!-- /.progress -->
            </div>

            <div style="margin-top:5px" :class="item.progressPercentByEdits != '' ? 'prgShow' : ''">
              <div class="d-flex mb-2">
                <div class="flex-grow-1">
                  <div>{{ $t('Progress') }}</div>
                </div>
                <div class="flex-shrink-0">
                  <div>{{item.progressPercent}}%</div>
                </div>
              </div>
              <div class="progress progress-sm animated-progess">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="`width: `+ item.progressPercent +`%`"
                ></div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row g-0 text-center text-sm-start align-items-center mb-4" >
          <div class="col-sm-6" >
              <div >
                  <p class="mb-sm-0 text-muted">{{ $t('Total') }} <span class="fw-semibold">{{ objPages.show }}</span> {{ $t('outOf') }} <span class="fw-semibold text-decoration-underline">{{ objPages.total }}</span></p>
              </div>
          </div>
          <!-- end col -->
          <div class="col-sm-6" >
              <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                  <div class="pagination-wrap hstack gap-2" style="margin-right:5px;" >
                    <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                      <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
                    </select>
                  </div>
                  <li v-for="(pageNumber, index) in objPages.links" :key="index" :class="`page-item copy ${pageNumber.current == 'true' ? 'active' : null}`">
                      <a class="page-link" @click="getdata(pageNumber.page)">{{ pageNumber.page }}</a>
                  </li>
              </ul>
          </div><!-- end col -->
      </div>

  <!-- создание проекта -->
  <createProjectBox 
    v-if="modalProjectBox" 
    @open = "openProject"
    @close="closeCreate" 
    :editform="this.obj"
    @change = 'change'
    :teamList = 'this.teamList'
    @saveProject = 'addProject'
    @getdata="this.getData()"
    :newform = 'this.newform'
  ></createProjectBox>

  <!-- карточка проекта -->
  <viewbox 
    v-for="form in modalView" 
    @close="close" 
    :obj="this.obj" 
    @remove="remove"
    @openCard="openCard" 
    @edit="edit" 
    @toConfirm="toConfirm"
    :ifPrevopen="ifPrevopen" 
    :ifNextopen="ifNextopen" 
    @prevOpen="prevOpen" 
    @nextOpen="nextOpen"
    @getData="getdata"
  ></viewbox>

  <viewclientnew 
    @close="closeModal"
    v-if="showmodal == true"
    :objcard="this.clientNew"
  ></viewclientnew>

</template>

<script>
import viewclientnew from '@/views/users/view/index.vue'
import Filter from "@/components/globaltable/globalFilter.vue";
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import infoblocks from '@/components/info/blocks'
import PageHeader from "@/components/page-header";
import createProjectBox from './new.vue'
import { Projects } from '@/API.js';
import viewbox from './view/index.vue'
import { storeS } from '../../store';
import { mutateStatusProject, mutatePriorityTicket, nameTag } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Projects();

export default {
  data() {
    return {
      archive: [],
      showmodal: false,
      clientNew: '',
      title: this.$t('navProjects'),
      // form:[],
      rows: [],
      category: "all",
      obj: {},
      search: "",
      newform: false,
      modalView: 0,
      modalProjectBox: false,
      infoblockShow: false,
      ifNextopen: "",
      ifPrevopen: "",
      objPages: '',
      limit: '10',
      limits: [
        { value: '5'},
        { value: '10'},
        { value: '25'},
        { value: '50'},
        { value: '75'},
        { value: '100'},
      ],
      dataParams: {
          status: true,
          page: "projects",  
      },
      objParams:{
        page: '1',
        pagelimit: '10',
        of: 'project_id',
        ot: 'desc', //asc
        search: '',
        pr1: '',
        pr2: '',
        priority: '',
        status: '',
        workers: '',
        category: '',
        confStatus: '',
        createWorkerId: '',
        tags: ''
      },
      rows: [],
      information: [
        {
            title: this.$t('Total'),
            value: "0",
            icon: "ri-ticket-2-line",
            color: "info"
        },
        {
            title: this.$t('new'),
            value: "0",
            icon: "mdi mdi-timer-sand",
            color: "warning"
        },
        {
            title: this.$t('statusInWork'),
            value: "0",
            icon: "mdi mdi-timer-sand",
            color: "warning"
        },
        {
            title: this.$t('completedProjects'),
            value: "0",
            icon: "ri-checkbox-circle-line",
            color: "success"
        }
      ],
    };
  },
  components: {
    viewclientnew,
    Filter,
    PageHeader,
    Multiselect,
    MoreHorizontalIcon,
    viewbox,
    createProjectBox,
    infoblocks
  },
  created(){
    // if(this.user.workerJob == 'developer' && this.perms[2000] != true) {
    //   if(this.checks['004']){
    //     this.objParams.inJobWorkerId = this.user.userid
    //   }
    //   this.objParams.category = 'Development'
    //   this.getdata(); 
    // } else if(this.user.workerJob == 'smm' && this.perms[2000] != true) {
    //   if(this.checks['004']){
    //     this.objParams.inJobWorkerId = this.user.userid
    //   }
    //   this.objParams.category = 'Content'
    //   this.getdata();
    // } else {
    //   if(this.checks['004']){
    //     this.objParams.inJobWorkerId = this.user.userid
    //   }
    //   this.getdata();
    // }
    
    if(localStorage.getItem('filters '+this.dataParams.page) != null || localStorage.getItem('filters '+this.dataParams.page) != undefined) {
      JSON.parse(localStorage.getItem('filters '+this.dataParams.page)).forEach(element => {
        this.objParams[element.nameFilter] = element.value
      });
      // if(!JSON.parse(localStorage.getItem('filters '+this.dataParams.page)).find(el => el.nameFilter == 'pr')) {
        this.getdata()
      // }
    } else {
      this.getdata();
    }
  },
  methods: {
    close() {
      if(this.modalView == 1) {
        this.modalView--
      }
      this.getdata(this.objParams.page)
    },
    closeModal() {
      this.showmodal = false;
      if(this.modals[this.modals.length-1].data != undefined) {
        this.obj = this.modals[this.modals.length-1].data
        this.modalView++
        this.modals.pop()
      }
      
    },
    openProject(e) {
      this.obj = e
      this.modalView++
    },
    openCard(e, e1) {
      this.modals.push({
        data: e1,
        type: 'project'
      })
      this.clientNew = e;
      this.showmodal = true
    },
    closeCreate() {
      this.newform = true;
      this.modalProjectBox = false
    },
    inputSearch(e){
      this.objParams.search = e;
      this.getdata();
    },
    changelimit(e){
      this.objParams.pagelimit = e;
      this.getdata();
    },
    getdata(page){
      this.objParams.page = page != undefined ? page : '1';
      apiServe.getProjects(this.objParams).then(result => {
          if(result.status === "done"){
            this.rows = result.data.items;
            this.objPages = result.data;

            this.information[0].value = result.data.total;
            this.information[1].value = result.data.countNew
            this.information[2].value = result.data.countInJob
            this.information[3].value = result.data.countCompleted
          }
      })
    },
    open: function(e){
      var id = e.projectId ? e.projectId : e;
      this.ifPrevopen = id == this.rows[0].projectId ? false : true;
      this.ifNextopen = id == this.rows[this.rows.length-1].projectId ? false : true;
      apiServe.getCardProjects(id).then(res =>{
        if(res.status === 'done'){
          this.obj = res.data
          this.modalView++
        } else {
          this.$toast.error('err')
        }
      })
    },
    prevOpen(e){
      var current_id = e;
      var prev_id = ""; 

      for(var value in this.rows){
        if(this.rows[value].projectId == current_id){
          prev_id = this.rows[Number(value)-Number('1')].projectId;
        }
      }
      this.open(prev_id);
    },
    nextOpen(e){
      var current_id = e;
      var next_id = "";

      for(var value in this.rows){
        if(this.rows[value].projectId == current_id){
          next_id = this.rows[Number(value)+Number('1')].projectId;
        }
      }
      this.open(next_id);
    },
    edit: function(e){
      this.obj = e
      this.newform = false;
      this.modalProjectBox = true
    },
    toCopy(e){
      apiServe.addProject(e).then(res => {
          if(res){
              this.$toast.success(this.$t('Copy'));
              this.$emit('close')
              this.eventBus.emit('saveProject', true)
          }
      })
    },
    changeFilter(e,e1) {
      if(e == 'status') {
        this.objParams.status = e1
        this.getdata()
      } else if(e=='priority') {
        this.objParams.priority = e1
        this.getdata()
      } else if(e=='pr') {
          this.objParams.pr1 = e1[0]
          this.objParams.pr2 = e1[1]
          this.getdata()
      } else if(e=="workerId") {
        this.objParams.createWorkerId = e1
        this.getdata()
      } else if(e=="inJobWorkerId") {
        this.objParams.workers = e1
        this.getdata()
      } else if(e=='team') {
        this.objParams.confStatus = e1;
        this.getdata()
      } else if(e == 'tags'){;
        this.objParams.tags = e1[0] == "" ? '' : JSON.stringify(e1); 
        this.getdata()
      } else if(e=='category') {
        this.objParams.category = e1
        this.getdata()
      } else {
        if((/\d{1,}-\d{1,}-\d{1,}/).test(e.target.defaultValue.split(' ')[0]) == true) {
          this.objParams.pr1 = e.target.defaultValue.split(' ')[e.target.defaultValue.split(' ').length-1]
          this.objParams.pr2 = e.target.defaultValue.split(' ')[0]
          this.getdata();
        }
      }
    },
    // toConfirm: function(e){
    //   apiServe.editProject(e.id, e).then(res =>{
    //       if(res){
    //           this.$toast.success(this.$t('сreatedT'));
    //           apiServe.deleteProject(e.id).then(del => {
    //               if(del){
    //                   apiServe.addProjArchive(e).then(result => {
    //                       if(result){
    //                           this.$toast.success(this.$t('gonetoArchive'));
    //                           this.modalShowview = false;
    //                           this.getdata();
    //                       }
    //                   })
    //               }
    //           })
    //       }
    //   })
    // },
    remove: function(e){
      apiServe.deleteProject(e.projectId).then(result => {
        if(result.status === 'done'){
          if(this.modalView == 1) {
            this.modalView--
          }
          this.getdata();
          this.$toast.success(this.$t('Removed'));
        }
      })
    },
    change() {
      this.getdata()
    },
    mutatestatus(e){
        return mutateStatusProject(e)
    },
    mutatepriority(e){
      return mutatePriorityTicket(e)
    },
    add() {
      this.modalProjectBox = true;
      this.newform = true
    },
    addProject() {
      this.getdata()
    },
    getDeadline(item){
      var date = "";
      this.dealines.forEach((el) => {
        if(el.deadlineId === item) {
          date = el.deadlineDate
        }
      })
      return date
    }
  },
  mounted(){
    this.eventBus.off('saveProject');
    this.eventBus.on('saveProject', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  },
  computed: {
    user(){
      return storeS.userbase
    },
    checks(){
      return storeS.checks
    },
    perms(){
      return storeS.perms
    },
    dealines(){
      return storeS.dealines
    },
    // modals() {
    //   return storeS.modalsOpen
    // }
  }
};
</script>

<style>
.card {
  margin: 15px 0px;
}
.prgShow {
  display: none;
}
.card:hover .prgShow {
  display: block;
  transition: .3s;
  cursor: pointer;
}
</style>
